import * as React from "react"
import { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { SideBySideMagnifier } from "react-image-magnifiers"
import { getImage, GatsbyImage } from "gatsby-plugin-image"


const ProductTemplate = ({ data, location }) => {
  const product = data.contentfulProduct
  const [activeImg, setActiveImg] = useState(product.thumbnail[0].file.fileName)
  // const { previous, next } = data

  const getActiveImgSrc = (fileName) => {
    const active = product.thumbnail.filter(image => image.file.fileName === fileName)
    return active.length ? active[0].file.url : product.thumbnail[0].file.url
  }
  return (
    <Layout location={location}>
      <Seo
        title={product.name}
      />
      <div className="page-content">
        <div className="container">
          <div className="product-details-top mb-2">
            <div className="row">
              <div className="col-md-6">
                <div className="product-gallery product-gallery-vertical">
                  <div className="row justify-content-center">
                    <figure className="product-main-image">
                      <SideBySideMagnifier alwaysInPlace imageSrc={getActiveImgSrc(activeImg)}
                                           imageAlt="product image"/>
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-details product-details-centered">
                  <h1 className="product-title">{product.name}</h1>

                  {product.thumbnail.length > 1 && (
                    <div className="product-content">
                      <div className="product-image-gallery row-cols-lg-3">
                        {product.thumbnail.map(image => (
                          <div key={image.file.fileName}
                               onClick={() => setActiveImg(image.file.fileName)}
                               className={`product-gallery-item ${image.file.fileName === activeImg && "active"}`}>
                            <img src={`https://${image.file.url}`} alt={image.title} />
                            {/*<GatsbyImage alt={image.title} image={getImage(image)} />*/}
                          </div>
                        ))}
                      </div>
                    </div>)}
                  {product.tags && (
                    <div className="product-details-footer">
                      <div className="product-cat">
                        <span>Thể loại:</span>{product.tags.map(tag => tag.name + " ")}
                        {/* <a href="#">{product.tags.map(tag => tag.name)}</a> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*<h2 className="title text-center mb-4">You May Also Like</h2>*/}
          {/*<div className="row justify-content-center">*/}
          {/*  <div className="product product-7 text-center">*/}
          {/*    <figure className="product-media">*/}
          {/*      <a href="product.html">*/}
          {/*        <img src="/images/products/product-4.jpg" alt="Product image" className="product-image" />*/}
          {/*      </a>*/}
          {/*    </figure>*/}
          {/*    <div className="product-body">*/}
          {/*      <div className="product-cat">*/}
          {/*        <a href="#">Women</a>*/}
          {/*      </div>*/}
          {/*      <h3 className="product-title"><a href="product.html">Brown paperbag waist <br />pencil skirt</a>*/}
          {/*      </h3>*/}

          {/*      <div className="product-nav product-nav-dots">*/}
          {/*        <a href="#" className="active" style={{ background: "#cc9966" }}><span*/}
          {/*          className="sr-only">Color name</span></a>*/}
          {/*        <a href="#" style={{ background: "#333333" }}><span className="sr-only">Color name</span></a>*/}
          {/*        <a href="#" style={{ background: "#806b3e" }}><span className="sr-only">Color name</span></a>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="product product-7 text-center">*/}
          {/*    <figure className="product-media">*/}
          {/*      <a href="product.html">*/}
          {/*        <img src="/images/products/product-6.jpg" alt="Product image" className="product-image" />*/}
          {/*      </a>*/}
          {/*    </figure>*/}
          {/*    <div className="product-body">*/}
          {/*      <div className="product-cat">*/}
          {/*        <a href="#">Jackets</a>*/}
          {/*      </div>*/}
          {/*      <h3 className="product-title"><a href="product.html">Khaki utility boiler jumpsuit</a>*/}
          {/*      </h3>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="product product-7 text-center">*/}
          {/*    <figure className="product-media">*/}
          {/*      <a href="product.html">*/}
          {/*        <img src="/images/products/product-11.jpg" alt="Product image" className="product-image" />*/}
          {/*      </a>*/}
          {/*    </figure>*/}
          {/*    <div className="product-body">*/}
          {/*      <div className="product-cat">*/}
          {/*        <a href="#">Shoes</a>*/}
          {/*      </div>*/}
          {/*      <h3 className="product-title"><a href="product.html">Light brown studded Wide fit wedges</a>*/}
          {/*      </h3>*/}
          {/*      <div className="product-nav product-nav-dots">*/}
          {/*        <a href="#" className="active" style={{ background: "#8b513d" }}><span*/}
          {/*          className="sr-only">Color name</span></a>*/}
          {/*        <a href="#" style={{ background: "#333333" }}><span className="sr-only">Color name</span></a>*/}
          {/*        <a href="#" style={{ background: "#d2b99a" }}><span className="sr-only">Color name</span></a>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="product product-7 text-center">*/}
          {/*    <figure className="product-media">*/}
          {/*      <a href="product.html">*/}
          {/*        <img src="/images/products/product-10.jpg" alt="Product image" className="product-image" />*/}
          {/*      </a>*/}
          {/*    </figure>*/}
          {/*    <div className="product-body">*/}
          {/*      <div className="product-cat">*/}
          {/*        <a href="#">Jumpers</a>*/}
          {/*      </div>*/}
          {/*      <h3 className="product-title"><a href="product.html">Yellow button front tea top</a>*/}
          {/*      </h3>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="product product-7 text-center">*/}
          {/*    <figure className="product-media">*/}
          {/*      <a href="product.html">*/}
          {/*        <img src="/images/products/product-7.jpg" alt="Product image" className="product-image" />*/}
          {/*      </a>*/}
          {/*    </figure>*/}
          {/*    <div className="product-body">*/}
          {/*      <div className="product-cat">*/}
          {/*        <a href="#">Jeans</a>*/}
          {/*      </div>*/}
          {/*      <h3 className="product-title"><a href="product.html">Blue utility pinafore denim dress</a>*/}
          {/*      </h3>*/}

          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </Layout>
  )
}
export default ProductTemplate
export const pageQuery = graphql`
query ProductBySlug($slug: String) {
  contentfulProduct(slug: {eq: $slug}) {
    name
    thumbnail {
      title
      file {
        fileName
        url
      }
      gatsbyImageData(formats: NO_CHANGE)
    }
    tags {
      name
    }
  }
}
`
